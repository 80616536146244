import React from 'react';
import '../CSS/PatioCleaning.css';
import patioimg from '../Assets/PatioCleaning.png'; // Ensure you have an image named PatioCleaning.png in your Assets folder
import { Link } from 'react-router-dom';

function PatioCleaning() {
  return (
    <div className="PatioCleaningModalContent">
      <h2>Patio Cleaning Services</h2>
      <br/>
      <img src={patioimg} alt="Patio Cleaning" className="PatioCleaningImage" />

      <p>
        Our patio cleaning service is tailored to restore the appearance of your outdoor living space. We remove dirt, moss, and other contaminants to make your patio look brand new. Using advanced techniques and eco-friendly solutions, we ensure your patio is spotless and ready for use.
      </p>

      <div className="PatioCleaningDetails">
        <h3>Why Choose Our Service?</h3>
        <ul>
          <li><strong>Thorough Cleaning:</strong> Our deep cleaning process ensures every inch of your patio is free from grime, restoring its original beauty.</li>
          <li><strong>Eco-Friendly Solutions:</strong> We use environmentally safe cleaning agents to protect your patio and surrounding plants.</li>
          <li><strong>Affordable Pricing:</strong> Get professional cleaning at competitive rates, ensuring value for your money.</li>
          <li><strong>Experienced Team:</strong> Our professionals have the expertise to clean all types of patio surfaces, delivering outstanding results every time.</li>
        </ul>
      </div>

      <div className="PatioCleaningCallToAction">
        <p>
          <strong>Want to rejuvenate your patio?</strong>
        </p>
        <Link to={'/quote'}>
          <button className="PatioCleaningQuoteButton">Get a Free Quote</button>
        </Link>
        <p>
          Reach out to us today and enjoy a spotless patio!
        </p>
      </div>
    </div>
  );
}

export default PatioCleaning;
