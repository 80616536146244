import React from 'react';
import './HeroSection.css';

const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-content">
      <h1 className="hero-title">Austin Prime Property Care</h1>
      <img src="/appcLogo.png" alt="Logo" className="App-logo-hero" />
      <p className="hero-subtitle">Your trusted partner for high-quality property care services</p>
    </div>
  </section>
);

export default HeroSection;
