import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import './Header.css';

const Header = ({ isMobile }) => {
  return (
    <AppBar position="fixed" className="header-bar">
      <Toolbar className="toolbar">
        {!isMobile && (
          <Box className="contact-info">
            <Typography variant="body1">Phone: (512) 766-9392</Typography>
            <Typography variant="body1">Email: austinprimepropertycare@gmail.com</Typography>
          </Box>
        )}
      </Toolbar>
      {isMobile && (
        <Box className="mobile-contact-info">
          <Typography variant="body1">Phone: (512) 766-9392</Typography>
          <Typography variant="body1">Email: austinprimepropertycare@gmail.com</Typography>
        </Box>
      )}
      <Link to={'/'}>
        <Box className="logo-box">
          <img src="/appcLogo.png" alt="Logo" className="App-logo" />
        </Box>
      </Link>
    </AppBar>
  );
};

export default Header;
