// HouseSiding.js
import React from 'react';
import '../CSS/HouseSiding.css';
import houseimg from '../Assets/HouseSide.png'
import { Link } from 'react-router-dom';

function HouseSiding() {
  return (
    <div className="HouseSidingModalContent">
      <h2>House Siding Cleaning Services</h2>
      <br/>
      <img src={houseimg} alt="House Siding Cleaning" className="HouseSidingImage" />

      <p>
        Our house siding cleaning service is designed to restore the beauty of your home by removing dirt, mildew, and other contaminants from your siding. We use specialized equipment and eco-friendly solutions to ensure your home’s exterior looks pristine.
      </p>

      <div className="HouseSidingDetails">
        <h3>Why Choose Our Service?</h3>
        <ul>
          <li><strong>Safe and Effective:</strong> We use methods that are tough on grime but gentle on your siding, preserving the integrity of your home's exterior.</li>
          <li><strong>Eco-Friendly Solutions:</strong> Our cleaning agents are environmentally safe, ensuring your home is clean and green.</li>
          <li><strong>Competitive Pricing:</strong> Enjoy top-quality service at a price that fits your budget.</li>
          <li><strong>Experienced Professionals:</strong> Our team is trained to handle all types of siding, delivering exceptional results every time.</li>
        </ul>
      </div>

      <div className="HouseSidingCallToAction">
        <p>
          <strong>Want to enhance your home's curb appeal?</strong>
        </p>
          <Link to={'/quote'}>
            <button className="HouseSidingQuoteButton">Get a Free Quote</button>
          </Link>
        <p>
          Contact us today and let us help you make your house shine!
        </p>
      </div>
    </div>
  );
}

export default HouseSiding;
