import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import {
  Grid, Card, CardContent, TextField, Button, Snackbar, Alert, Typography, Container
} from '@mui/material';
import emailjs from '@emailjs/browser';
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import ServiceList from './components/ServiceList/ServiceList';
import ImageGallery from './components/ImageGallery/ImageGallery';
import TestimonialsCarousel from './components/TestimonialsCarousel/TestimonialsCarousel';
import { Link } from 'react-router-dom';

const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const screenMaxSize = 950;

function HomePage() {
  const [submitted, setSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= screenMaxSize);
  const form = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= screenMaxSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form.current, userId)
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true);
          setTimeout(() => setSubmitted(false), 5000); // Hide the submitted message after 5 seconds
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };

  return (
    <div className="App">
      <Header isMobile={isMobile} />

      <Container sx={{ paddingTop: '80px', paddingBottom: '80px' }}>
        <HeroSection isMobile={isMobile} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom align="left">About Us</Typography>
                <Typography variant="body1" className="aboutUsText">
                  At Austin Prime Property Care, we are dedicated to delivering exceptional power washing services tailored to meet the unique needs of Austin, Texas. Whether it's rejuvenating driveways, restoring the beauty of house sidings, or revitalizing decks and patios, our skilled team uses cutting-edge equipment and eco-friendly solutions to ensure every job is done to perfection.
                  <br /><br />
                  What sets us apart is our unwavering commitment to quality and customer satisfaction. We understand that your home is your sanctuary, and we take pride in helping you maintain its beauty and integrity. With a focus on thoroughness and care, we treat every property as if it were our own, ensuring that your experience with us exceeds expectations.
                  <br /><br />
                  Let us help you enhance your curb appeal and protect your investment with our comprehensive power washing services. Trust Austin Prime Property Care to bring out the best in your property.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center">
                  Ready to Transform Your Property?
                </Typography>
                <Typography variant="body1" align="center" className="ctaText">
                  Don't wait any longer to restore the beauty of your home. Click below to get a free, no-obligation quote and see how we can make your property shine.
                </Typography>
                <div className="ctaLinkContainer">
                  <Link to="/quote" className="ctaLink">
                    Get a Free Quote Now
                  </Link>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom align="left">Services</Typography>
                <ServiceList />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom align="left">Gallery</Typography>
                <ImageGallery />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom align="left">Testimonials</Typography>
                <TestimonialsCarousel />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom align="left">Contact Us</Typography>
                {submitted ? (
                  <Typography variant="body1">Thank you for your message! We'll get back to you soon.</Typography>
                ) : (
                  <form ref={form} onSubmit={sendEmail} id="myForm">
                    <TextField
                      fullWidth
                      label="Name"
                      id="name"
                      name="user_name"
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      id="email"
                      name="user_email"
                      type="email"
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Message"
                      id="message"
                      name="message"
                      multiline
                      rows={4}
                      margin="normal"
                      required
                    />
                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                  </form>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Snackbar open={submitted} autoHideDuration={6000} onClose={() => setSubmitted(false)}>
        <Alert onClose={() => setSubmitted(false)} severity="success" sx={{ width: '100%' }}>
          Thank you for your message! We'll get back to you soon.
        </Alert>
      </Snackbar>

      <footer className="App-footer">
        <Typography variant="body2">&copy; 2024 Austin Prime Property Care. All rights reserved.</Typography>
      </footer>
    </div>
  );
}

export default HomePage;
