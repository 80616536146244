import React from 'react';
import { Typography, Container } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import './TestimonialsCarousel.css';

const testimonials = [
  {
    quote: "We love how the deck looks now!",
    author: "Kevin & Rose",
  },
  {
    quote: "Excellent service and professional. My wood patio looks brand new!",
    author: "Ayse",
  },
  {
    quote: "Top-notch power washing service. Very satisfied with the results!",
    author: "Mark",
  },
];

const TestimonialsCarousel = () => (
  <Carousel
    indicators={false}
    navButtonsAlwaysInvisible={true}
    autoPlay={true}
    interval={7000} // Rotate every 7 seconds
    animation="fade" // Smooth fade animation
    duration={1000}
  >
    {testimonials.map((testimonial, index) => (
      <Container key={index} className="testimonial-container">
        <Typography variant="h5" component="h2" className="testimonial-quote">
          {testimonial.quote}
        </Typography>
        <Typography variant="body2" color="textSecondary" className="testimonial-author">
          - {testimonial.author}
        </Typography>
      </Container>
    ))}
  </Carousel>
);

export default TestimonialsCarousel;
