import React, { useRef, useState, useEffect } from 'react';
import { Grid, Card, CardContent, TextField, Button, Snackbar, Alert, Typography, Container } from '@mui/material';
import emailjs from '@emailjs/browser';
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';
import './QuoteFormPage.css'; // Import the CSS file

const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAIL_JS_QUOTES_TEMPLATE_ID;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const screenMaxSize = 950;

function QuoteFormPage() {
  const [submitted, setSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= screenMaxSize);
  const form = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= screenMaxSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_EMAIL_JS_QUOTES_TEMPLATE_ID)

    emailjs.sendForm(serviceId, templateId, form.current, userId)
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true);
          setTimeout(() => {
            setSubmitted(false);
            navigate('/'); // Redirect to home page after submission
          }, 4000); // Redirect after 4 seconds
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };


  return (
    <div className="QuoteFormPage">
      <Header isMobile={isMobile} />
      <Container className="form-container">
        <Grid item xs={12} sm={8} md={6} className="quote-form">
          <Card>
          {submitted ? (
            <>
              <CardContent>
                <Typography variant="h4" gutterBottom align="center">
                  Thank You for Reaching Out!
                </Typography>
                <Typography variant="body1" align="center">
                  Your request has been received and our team is already on it. We will contact you to verify the address and time shortly.
                </Typography>
              </CardContent>
            </>
            ) : (
            <CardContent>
              <Typography variant="h4" gutterBottom align="center">Request a Free Quote</Typography>
                <form ref={form} onSubmit={sendEmail} id="quoteForm">
                  <TextField
                    fullWidth
                    label="Name"
                    id="name"
                    name="user_name"
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    id="email"
                    name="user_email"
                    type="email"
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    id="phone"
                    name="user_phone"
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Address"
                    id="address"
                    name="user_address"
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Preferred Date and Time"
                    id="date_time"
                    name="user_date_time"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    required
                    helperText="Select a convenient time for us to visit and provide your quote."
                  />
                  <Button type="submit" variant="contained" color="primary" className="submit-button">Submit</Button>
                </form>
            </CardContent>
        )}
          </Card>
        </Grid>
          <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={() => navigate('/')}>
            Back to Home
          </Button>
      </Container>


      <Snackbar open={submitted} autoHideDuration={6000} onClose={() => setSubmitted(false)}>
        <Alert onClose={() => setSubmitted(false)} severity="success" sx={{ width: '100%' }}>
          Thank you for your request! We'll get back to you soon.
        </Alert>
      </Snackbar>

      <footer className="App-footer">
        <Typography variant="body2">&copy; 2024 Austin Prime Property Care. All rights reserved.</Typography>
      </footer>
    </div>
  );
}

export default QuoteFormPage;
