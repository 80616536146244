import React, { useState } from 'react';
import { Grid, Card, CardMedia, Modal } from '@mui/material';
import './ImageGallery.css';

const images = [
  { src: '/images/Railing.png', title: 'Deck Cleaning' },
  { src: '/images/Chair.png', title: 'Patio Furniture' },
  { src: '/images/WalkWay.png', title: 'Concrete WalkWay' },
  // { src: '/images/image4.png', title: 'Patio Cleaning' },
];

const ImageGallery = () => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});

  const handleOpen = (image) => {
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage({});
  };

  return (
    <div>
      <Grid
        container
        spacing={3}
        justifyContent="center"  // Centers items horizontally
        alignItems="center"      // Centers items vertically
        className="image-gallery"
      >
        {images.map((image, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={index}
            display="flex"
            justifyContent="center"
          >
            <Card className="gallery-card" onClick={() => handleOpen(image)}>
              <CardMedia
                component="img"
                alt={image.title}
                height="200"
                image={image.src}
                title={image.title}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="image-modal"
      >
        <div className="modal-content" onClick={handleClose}>
          <img src={currentImage.src} alt={currentImage.title} className="modal-image" />
        </div>
      </Modal>
    </div>
  );
};

export default ImageGallery;
