// Modal.js
import React, { useEffect, useRef } from 'react';
import './Modal.css'

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.body.classList.add('modal-open');
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className='ModalContainer'>
      <div className='ModalContents' ref={modalRef}>
        {children}
      </div>
    </div>
  );
};


export default Modal;
