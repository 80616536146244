import React, { useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Card, CardContent } from '@mui/material';
import { Home, Deck, Yard } from '@mui/icons-material';
import './ServiceList.css';
import Modal from '../Modal/Modal';
import Concrete from '../services/concrete';
import HouseSiding from '../services/houseSiding';
import DeckCleaning from '../services/deckCleaning';
import PatioCleaning from '../services/PatioCleaning';




function ServiceList() {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalPage, setModalPage] = useState(null)


  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)


  const handleModalPage = (page) =>{
    setModalPage(page)
    openModal()
  }




  return (
    <>

    <Modal isOpen={isModalOpen} onClose={closeModal} children={modalPage}/>



      <Card style={{ overflow: 'visible', boxShadow: 'none', margin: '0', padding: '0' }}>
        <CardContent style={{ overflow: 'visible' }}>
          <List className="service-list">
            <ListItem className="service-list-item">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#ADD8E6' }}> {/* Light Blue for Concrete Cleaning */}
                  <Home />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<b>Concrete Cleaning</b>}
                secondary="High-pressure cleaning for all concrete surfaces."
                onClick={() => handleModalPage(<Concrete/>)}
              />
              <div className="tooltip">
                <span className="tooltiptext">Detailed information about Concrete Cleaning.</span>
              </div>
            </ListItem>
            <ListItem className="service-list-item">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#90EE90' }}> {/* Light Green for House Siding Cleaning */}
                  <Deck />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<b>House Siding Cleaning</b>}
                secondary="Gentle but effective cleaning for your house exterior."
                onClick={() => handleModalPage(<HouseSiding/>)}
              />
              <div className="tooltip">
                <span className="tooltiptext">Detailed information about House Siding Cleaning.</span>
              </div>
            </ListItem>
            <ListItem className="service-list-item">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#FFA07A' }}> {/* Light Coral for Deck Cleaning */}
                  <Deck />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<b>Deck Cleaning</b>}
                secondary="Revitalize your deck with our professional cleaning services."
                onClick={() => handleModalPage(<DeckCleaning/>)}
              />
              <div className="tooltip">
                <span className="tooltiptext">Detailed information about Deck Cleaning.</span>
              </div>
            </ListItem>
            <ListItem className="service-list-item">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#D8BFD8' }}> {/* Light Purple for Patio Cleaning */}
                  <Yard />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<b>Patio Cleaning</b>}
                secondary="Ensure your patio looks its best with our thorough cleaning."
                onClick={() => handleModalPage(<PatioCleaning/>)}
              />
              <div className="tooltip">
                <span className="tooltiptext">Detailed information about Patio Cleaning.</span>
              </div>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  )
}

export default ServiceList;
