import React from 'react';
import '../CSS/DeckCleaning.css';
import deckimg from '../Assets/DeckCleaning.jpg'; // Ensure you have an image named DeckCleaning.png in your Assets folder
import { Link } from 'react-router-dom';

function DeckCleaning() {
  return (
    <div className="DeckCleaningModalContent">
      <h2>Deck Cleaning Services</h2>
      <br/>
      <img src={deckimg} alt="Deck Cleaning" className="DeckCleaningImage" />

      <p>
        Our deck cleaning service brings out the natural beauty of your wooden deck by removing dirt, algae, and other contaminants. We use specialized equipment and eco-friendly solutions to ensure your deck looks as good as new.
      </p>

      <div className="DeckCleaningDetails">
        <h3>Why Choose Our Service?</h3>
        <ul>
          <li><strong>Gentle on Wood:</strong> Our cleaning methods are designed to be tough on grime but gentle on your deck, preserving the wood’s integrity.</li>
          <li><strong>Eco-Friendly Solutions:</strong> We use environmentally safe cleaning agents to protect both your deck and the surrounding environment.</li>
          <li><strong>Affordable Rates:</strong> Enjoy high-quality service without breaking the bank.</li>
          <li><strong>Professional Expertise:</strong> Our team is skilled in treating all types of wood, delivering exceptional results every time.</li>
        </ul>
      </div>

      <div className="DeckCleaningCallToAction">
        <p>
          <strong>Ready to revitalize your deck?</strong>
        </p>
        <Link to={'/quote'}>
          <button className="DeckCleaningQuoteButton">Get a Free Quote</button>
        </Link>
        <p>
          Contact us today to bring your deck back to life!
        </p>
      </div>
    </div>
  );
}

export default DeckCleaning;
