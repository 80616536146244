// Concrete.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Concrete.css';
import concreteCleaningImage from '../Assets/ConcreteImage.jpg'; // Ensure you have an appropriate image

function Concrete() {

  const handleQuote = () => {

  }
  return (
    <div className="ConcreteModalContent">
      <h2>Expert Concrete Cleaning Services</h2>
      <br/>
      <img src={concreteCleaningImage} alt="Concrete Cleaning in Progress" className="ConcreteImage" />

      <p>
        Transform your concrete surfaces with our high-pressure cleaning services. We specialize in removing tough stains, grime, and dirt, leaving your concrete looking like new.
      </p>

      <div className="ConcreteDetails">
        <h3>What We Offer:</h3>
        <ul>
          <li><strong>Powerful Cleaning:</strong> Our state-of-the-art electric power washer ensures thorough cleaning, no matter the surface.</li>
          <li><strong>Eco-Friendly Solutions:</strong> We use environmentally safe cleaning agents that are tough on dirt but gentle on the planet.</li>
          <li><strong>Competitive Rates:</strong> Get top-quality service without breaking the bank. We offer transparent, competitive pricing.</li>
          <li><strong>Experienced Team:</strong> Our trained professionals deliver consistent, high-quality results every time.</li>
        </ul>
      </div>

      <div className="ConcreteCallToAction">
        <p>
          <strong>Ready to bring your concrete back to life?</strong>
        </p>
        <Link to={'/quote'}>
          <button className="ConcreteQuoteButton" onClick={handleQuote}>Get a Free Quote</button>
        </Link>
        <p>
          Contact us today and see the difference our services can make!
        </p>
      </div>
    </div>
  );
}

export default Concrete;
